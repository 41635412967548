import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["leadTypeRadio", "parentLeadSelect"];

  connect () {
    this.originalParentLeadSelectValue = this.parentLeadSelectTarget.value;

    this.toggleParentLeadSelect();
  }

  toggleParentLeadSelect () {
    const leadTypeRadioValue = this.leadTypeRadioTargets.find(el => el.tagName.toLowerCase() === "sl-radio-group").value;
    if (leadTypeRadioValue === "bolt_on") {
      this.parentLeadSelectTarget.classList.remove("hidden");
      this.parentLeadSelectTarget.value = this.originalParentLeadSelectValue;
    } else {
      this.parentLeadSelectTarget.classList.add("hidden");
      this.parentLeadSelectTarget.value = "";
    }
  }
}
